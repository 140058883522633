const notifications = [
  {
    title: "Maker Faire Kyoto 2024に出展しました！",
    date: new Date("2024-04-28"),
    content: `2024年4月27日（土）〜28日（日）にかけて、けいはんなオープンイノベーションセンター（KICK）にて行われたMaker Faire Kyoto 2024のIPAブースにて、エスプレッソマシンの展示と試飲を行いました。<br>
    Maker Faire Kyoto 2024 公式HP：<a href="https://makezine.jp/event/mfk2024/" target="_blank" rel="noopener noreferrer">https://makezine.jp/event/mfk2024/</a>`,
    tags: ["出展"]
  },
  {
    title: "2023年度未踏IT人材発掘育成事業 成果報告会の動画が公開されました！",
    date: new Date("2024-04-19"),
    content: `2023年度未踏IT人材発掘育成事業 成果報告会の動画が公開されました。以下のリンクからご覧いただけます。<br>
    <a href="https://www.youtube.com/watch?v=Ol6Wko-c7e8&list=PLi57U_f9scILyBglYKcjixGLs-o0bshhD&index=18&ab_channel=IPAChannel" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=Ol6Wko-c7e8&list=PLi57U_f9scILyBglYKcjixGLs-o0bshhD&index=18&ab_channel=IPAChannel</a>`,
    videoUrl: "https://www.youtube.com/embed/Ol6Wko-c7e8",
    tags: ["動画", "報告会"]
  },
  {
    title: "未踏会議2024に出展しました！",
    date: new Date("2024-03-10"),
    content: `未踏会議2024に出展しました。以下のリンクからインタビュー動画をご覧いただけます。<br>
    <a href="https://youtu.be/MJPg4eyicQY?t=17913" target="_blank" rel="noopener noreferrer">https://youtu.be/MJPg4eyicQY?t=17913</a>`,
    videoUrl: "https://www.youtube.com/embed/MJPg4eyicQY?start=17913",
    images: [
      "/IMG_0450-Edit-1024x576.webp",
      "/IMG_0447-Edit-1024x576.webp"
    ],
    additionalContent: `
      また、展示内容については以下の通りです。2023年度未踏（担当プロジェクトマネージャー：落合陽一氏）で開発した、エスプレッソマシン自動制御ソフト「Connoisseur」。精密温度制御・自動ポンプ制御によって抽出条件を精密に制御することで、エスプレッソの品質を一定に保つことが可能です。自分の味の感覚に頼らず、エスプレッソテクノロジーで好みに合わせた抽出を実現します。会場ではモカを使って完熟マンゴーの味わいが楽しめるエスプレッソを訪問者に振る舞いました。開発者である岡田拓真氏が、その技術を披露しました。<br><br>

      引用元および詳細については以下のリンクをご参照ください。<br>
      <a href="https://south65.jp/20240315mkmd/" target="_blank" rel="noopener noreferrer">https://south65.jp/20240315mkmd/</a><br><br>
      以下は引用元からの写真です。`,
    tags: ["出展", "インタビュー"]
  },
  {
    title: "IPA公式HPで2023年度未踏IT人材発掘育成事業 成果報告書が公開されました！",
    date: new Date("2024-04-22"),
    content: `プロジェクト全体評価 (PDF:170 KB)：<a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/zentaihyouka-ochiai.pdf" target="_blank" rel="noopener noreferrer">https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/zentaihyouka-ochiai.pdf</a><br>
    プロジェクト成果<br>
    概要 (PDF:985 KB)：<a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/seikagaiyou-oc-2.pdf" target="_blank" rel="noopener noreferrer">https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/seikagaiyou-oc-2.pdf</a><br>
    詳細 (PDF:408 KB)：<a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/seikashosai-oc-2.pdf" target="_blank" rel="noopener noreferrer">https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/seikashosai-oc-2.pdf</a><br>
    プロジェクト評価書 (PDF:192 KB)：<a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/hyouka-oc-2.pdf" target="_blank" rel="noopener noreferrer">https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000azzl-att/hyouka-oc-2.pdf</a>`,
    tags: ["報告書", "成果発表"]
  },
  {
    title: "2023年度未踏IT人材発掘・育成事業に採択されました！",
    date: new Date("2023-06-19"),
    content: `2023年度未踏IT人材発掘・育成事業に採択されました。詳細は以下のリンクをご参照ください。<br>
    <a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/gaiyou_oc-2.html" target="_blank" rel="noopener noreferrer">2023年度未踏IT人材発掘・育成事業 公式HP</a>`,
    tags: ["採択"]
  },
  {
    title: "さくらナレッジで特集記事が公開されました！",
    date: new Date("2024-05-21"),
    content: `さくらインターネット株式会社のGPU（V100）を利用し、乳化量評価システムを開発。<br>
    詳細記事<br>
    大学生がエスプレッソの味を完全自動化!?Connoisseur 〜新たな体験を切り拓く革新的システムに迫る〜<br>
    <a href="https://knowledge.sakura.ad.jp/37555/" target="_blank" rel="noopener noreferrer">https://knowledge.sakura.ad.jp/37555/</a>`,
    tags: ["特集記事"]
  },
  {
    title: "立命館起業・事業化推進室 RIMIXイベントでエスプレッソマシンを展示",
    date: new Date("2023-12-09"),
    content: `立命館起業・事業化推進室 RIMIXが主催するイベントで開発中のエスプレッソマシンを展示しました。立命館大学総長・理事長にもご試飲いただき、大好評でした。<br>
    公式HP<br>
    IMPACT MAKERS DAY｜IMPACT MAKERSが、集う日。2023.12.9<br>
    <a href="https://r-rimix.com/programs/imd23/" target="_blank" rel="noopener noreferrer">https://r-rimix.com/programs/imd23/</a>`,
    tags: ["展示", "イベント"]
  },
  {
    title: "京都船岡山OPEN PARKマルシェに出店しました！",
    date: new Date("2023-12-17"),
    content: `2023年12月17日に京都船岡山OPEN PARKマルシェに出店しました。詳細は以下のリンクをご覧ください。<br>
    <a href="https://www.instagram.com/p/C1hMmodJmGF/?img_index=1" target="_blank" rel="noopener noreferrer">https://www.instagram.com/p/C1hMmodJmGF/?img_index=1</a>`,
    tags: ["出店", "マルシェ"]
    },
  {
    title: "IPAより「2023年度未踏IT人材発掘・育成事業「スーパークリエータ」に認定されました！",
    date: new Date("2024-06-03"),
    content: `IPA公式HP「2023年度未踏IT人材発掘・育成事業の「スーパークリエータ」25名を認定」：<a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/supercreator.html" target="_blank" rel="noopener noreferrer">https://www.ipa.go.jp/jinzai/mitou/it/2023/supercreator.html</a><br>
    2023年度未踏IT人材発掘・育成事業スーパークリエータ紹介資料（冊子P16）：<a href="https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000i86f-att/it2023_sc_booklet.pdf" target="_blank" rel="noopener noreferrer">https://www.ipa.go.jp/jinzai/mitou/it/2023/m42obm000000i86f-att/it2023_sc_booklet.pdf</a><br>
    経済産業省公式HP「2023年度未踏IT人材発掘・育成事業スーパークリエータを認定しました！」：<a href="https://www.meti.go.jp/press/2024/06/20240603002/20240603002.html" target="_blank" rel="noopener noreferrer">https://www.meti.go.jp/press/2024/06/20240603002/20240603002.html</a>`,
    tags: ["認定", "スーパークリエータ"]
  }
];

// 日付順にソート
notifications.sort((a, b) => b.date - a.date);

export default notifications;

import React from 'react';

const AboutPage = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1 className="text-center">About Us</h1>
      <p className="text-center">私たちのビジョンは、エスプレッソの美味しさによって生活に感動を与える存在になることです。高品質のエスプレッソマシンを通じて、家庭でもカフェのような味を楽しんでいただきたいと考えています。</p>

      <h2 className="text-center">Meet Our Founder</h2>
      <div className="d-flex justify-content-center">
        <div className="text-center mb-4">
          <img src="/自己紹介.png" alt="Takuma Okada" className="img-fluid rounded-circle mb-3" style={{ width: '150px', height: '150px' }} />
          <h3>Takuma Okada</h3>
          <h5 className="text-muted">Founder & IPA認定スーパークリエイター</h5>
          <p>エスプレッソの情熱家であり、高品質なコーヒーマシンを開発しています。IPA認定スーパークリエイターとして、革新的な技術とデザインを追求し続けています。</p>
        </div>
      </div>

      <section id="contact" className="text-center">
        <h2>Contact Us</h2>
        <p>Contact us at: <a href="https://x.com/mtakuma32" target="_blank" rel="noopener noreferrer">https://x.com/mtakuma32</a></p>
      </section>
    </div>
  );
};

export default AboutPage;

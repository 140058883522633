import React from 'react';
import { Link } from 'react-router-dom';
import notifications from './notificationsData';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}.${month}.${day}`;
};

const NewsPage = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1 className="mb-5">News</h1> {/* タイトルと一覧の距離を広げる */}
      <div className="recent-news-list">
        {notifications.map((notification, index) => (
          <Link to={`/news/${index}`} className="recent-news-item" key={index}> {/* 余白を調整 */}
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <small className="text-muted me-5" style={{ fontSize: '80%' }}>{formatDate(notification.date)}</small>
                <h5 className="mb-1 text-start" style={{ fontSize: '100%' }}>{notification.title}</h5>
              </div>
              <div className="tags">
                {notification.tags.map((tag, tagIndex) => (
                  <span key={tagIndex} className="badge bg-secondary me-2">{tag}</span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NewsPage;

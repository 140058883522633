import React from 'react';
import './App.css';
import notifications from './notificationsData';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import NewsPage from './NewsPage';
import NotificationDetail from './NotificationDetail';
import ResearchPage from './ResearchPage';
import ProductPage from './ProductPage';
import AboutPage from './AboutPage';
import FlavorPage from './FlavorPage'; // 新しいFlavorPageをインポート

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}.${month}.${day}`;
};

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav className="App-nav">
            <Link to="/">Overview</Link>
            <Link to="/news">News</Link>
            <Link to="/flavor">Flavor</Link>
            <Link to="/product">Product</Link>
            <Link to="/research">Research</Link>
            <Link to="/about">About</Link>
          </nav>
        </header>
        <main className="container mt-5 pt-5">
          <Routes>
            <Route path="/" element={
              <>
                <section id="video">
                  <div className="d-flex justify-content-center mb-4">
                    <iframe 
                      width="560" 
                      height="315" 
                      src="https://www.youtube.com/embed/Ol6Wko-c7e8?start=824" 
                      title="YouTube video player" 
                      frameBorder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowFullScreen>
                    </iframe>
                  </div>
                </section>                
                <section id="top">
                  <h2 className="text-start mb-4">Recent News</h2>
                  <div className="recent-news-list">
                    {notifications.slice(0, 5).map((notification, index) => (
                      <Link to={`/news/${index}`} className="recent-news-item" key={index}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <small className="text-muted me-5" style={{ fontSize: '80%' }}>{formatDate(notification.date)}</small>
                            <h5 className="mb-1 text-start" style={{ fontSize: '100%' }}>{notification.title}</h5>
                          </div>
                          <div className="tags">
                            {notification.tags.map((tag, tagIndex) => (
                              <span key={tagIndex} className="badge bg-secondary me-2">{tag}</span>
                            ))}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Link to="/news" className="btn btn-outline-secondary rounded-pill">
                      All News...
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L9.293 8H1.5a.5.5 0 0 1 0-1h7.793l-2.147-2.146a.5.5 0 1 1 .708-.708l3 3z"/>
                      </svg>
                    </Link>
                  </div>
                </section>
                <section id="contact">
                  <h2>Contact Us</h2>
                  <p>Contact: <a href="https://x.com/mtakuma32" target="_blank" rel="noopener noreferrer">https://x.com/mtakuma32</a></p>
                </section>
              </>
            } />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:id" element={<NotificationDetail />} />
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/flavor" element={<FlavorPage />} />
          </Routes>
        </main>
        <footer className="bg-light text-center py-3">
          <p>© 2024 TAKUMA OKADA. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';

const ResearchPage = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1>Research</h1>
      <p>私たちはエスプレッソのさらなる品質向上を目指して品質把握技術の開発に取り組んでいます。</p>

      <hr className="my-5" />

      <h2 className="text-start mt-5">乳化量解析</h2>
      <div className="d-flex align-items-center">
        <div className="gif-container large-gif">
          <img src="/230728_generate_crop.gif" alt="New Research GIF" className="img-fluid" />
        </div>
        <p className="ms-3 text-wide align-self-center">
          エスプレッソでは美しさの一つの基準として泡の量があります。豆の質や抽出方法により、泡の品質は異なります。
          私たちは泡の量を評価する技術を開発し、その変動を可視化することに成功しました。この動画は、泡の量の変動の様子を示しています。
        </p>
      </div>
      
      <div className="row mt-4">
        <div className="col-md-6">
          <img src="/liquid_transition.png" alt="Liquid Transition" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <p>
            エスプレッソの泡は、コーヒー豆に含まれる二酸化炭素と油が細かく混ざり合う乳化により生成されます。
            この泡の量を世界で初めて画像解析で定量化しました。人の手でアノテーションを行わないことで、大量のデータ解析が可能となります。
          </p>
        </div>
          </div>
          
      <div className="row mt-4">
        <div className="col-md-6">
          <img src="/bin-chart.png" alt="Bin Chart" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <p>
            泡の量の数値化により、温度で泡の量の変動を捉えることに成功しました。この実験では、90度付近で乳化量が最大となっていることが確認できました。
          </p>
        </div>
      </div>          

      <h2 className="text-start mt-5">成分分析</h2>
      <div className="row mt-4">
        <div className="col-md-6 large-image">
          <img src="/hplc.png" alt="HPLC Analysis" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <p>
            温度による泡や味の変動を調査するために成分分析を行い、特定のパターンが見られることを確認しています。
          </p>
        </div>
      </div>

      <h2 className="text-start mt-5">油量分析</h2>
      <div className="row mt-4">
        <div className="col-md-6 large-image">
          <img src="/oil.gif" alt="Oil Analysis" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <p>
            泡に関係の深いエスプレッソ中の油の量を調べる油量分析を行い、温度によって油の量が変化することを確認しました。
            この結果は先行研究とも一致しています。
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResearchPage;

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import notifications from './notificationsData';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}.${month}.${day}`;
};

const NotificationDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const notification = notifications[parseInt(id)];

  if (!notification) {
    return <div>Notification not found</div>;
  }

  return (
    <div className="container mt-5 pt-5">
      <h1>{notification.title}</h1>
      <p>{formatDate(new Date(notification.date))}</p>
      <p dangerouslySetInnerHTML={{ __html: notification.content }}></p>

      {/* 動画の表示 */}
      {notification.videoUrl && (
        <div className="video-container mb-4">
          <iframe 
            width="560" 
            height="315" 
            src={notification.videoUrl} 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>
      )}

      {/* 追加のコンテンツを表示 */}
      {notification.additionalContent && (
        <p dangerouslySetInnerHTML={{ __html: notification.additionalContent }}></p>
      )}

      {/* 画像を表示 */}
      {notification.images && (
        <div className="image-gallery">
          {notification.images.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 1}`} className="img-fluid mb-3" />
          ))}
        </div>
      )}

      {/* 戻るボタン */}
      <button className="btn btn-outline-secondary" onClick={() => navigate('/news')}>
        お知らせ一覧に戻る
      </button>
    </div>
  );
};

export default NotificationDetail;
